import React from 'react'

import MainLayout from '../../layouts/MainLayout/MainLayout'
import SEO from '../../elements/SEO/SEO'
import Heading from '../../elements/Heading/Heading'
import Section from '../../components/Section/Section'
import Grid from '@material-ui/core/Grid'
import Accordion from '../../components/Accordion/Accordion'
import Text from '../../elements/Text/Text'
import Divider from '../../elements/Divider/Divider'

import connectionsBackground from '../../images/backgrounds/connections-2.jpg'
import Link from '../../elements/Link/Link'

const FaqPage = () => {
  const faq = [
    {
      q: 'What is enquirehub?',
      a:
        'We are a secure global online business platform, designed to help businesses connect with standardised information across industry sectors. We aim to endorse global commerce, improving accessibility and visibility, in turn helping businesses flourish and thrive. <a href="/about">Read more about us</a>.',
    },
    {
      q: 'Why enquirehub?',
      a:
        'We want to help your business grow; your success is, equally, ours. We believe that there is no one platform that focuses on promoting businesses like we do. <a href="/about">Learn more about how enquirehub can help your business</a>.',
    },
    {
      q: 'When will enquirehub be launched?',
      a:
        'Our team is working to launch enquirehub in late 2020. You can sign up with your email to keep updated on when the website will be launched.',
    },
    {
      q: 'How is my data protected?',
      a:
        'All information provided to us is secured from end to end using 256-Bit SSL encryption and only accessed by members of the enquirehub team. We will never share your details with anyone else.',
    },
    {
      q: 'Will I have to pay to use enquirehub?',
      a:
        'No, you will not. At launching all our services will be free to use. We plan to introduce premium memberships in the future, offering advanced features. This will be entirely optional, and to help you decide if you would like to opt in, when the system rolls out all current members will receive relevant information on membership details.',
    },
  ]

  return (
    <MainLayout isHeaderTransparent>
      <SEO title="FAQ" />
      <Section
        backgroundImage={connectionsBackground}
        isBackgroundFixed
        hasBackgroundOverlay
        padding="xxLarge 0 large"
      >
        <Heading level={1} margin="0 0 large" color="white">
          Frequently Asked Questions
        </Heading>
      </Section>
      <Section padding="medium 0 0" alignText="center">
        <Text margin="0">
          Check out our answers to some of the most frequently asked questions
          below. Don&apos;t forget to signup to our newsletter on the{' '}
          <Link to="/" underline="reverse">
            home page
          </Link>{' '}
          to be notified when we launch!
        </Text>
        <Divider margin="medium auto" isThin />
      </Section>
      <Section padding="large 0" backgroundColor="lightGrey">
        <Grid container>
          <Grid item sm={2} />
          <Grid item sm={10}>
            <Accordion data={faq} />
          </Grid>
        </Grid>
      </Section>
    </MainLayout>
  )
}

export default FaqPage
