import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as classnames from 'classnames'

import Heading from '../../elements/Heading/Heading'
import Text from '../../elements/Text/Text'
import IconWrapper from '../../elements/IconWrapper/IconWrapper'
import Button from '../../elements/Button/Button'
import Divider from '../../elements/Divider/Divider'
import Spacer from '../../elements/Spacer/Spacer'

import ReactHtmlParser from 'react-html-parser'

import { KeyboardArrowDown } from '@material-ui/icons'
import accordionStyles from '../../styles/components/accordion.module.scss'

const Accordion = ({ data }) => {
  const [openIndexes, setOpenIndexes] = useState([])

  const showHide = i => {
    if (openIndexes.includes(i)) {
      setOpenIndexes(openIndexes.filter(index => index !== i))
    } else {
      setOpenIndexes([...openIndexes, i])
    }
  }

  return (
    <>
      {data.map((panel, i) => (
        <div
          key={i}
          className={accordionStyles.accordion}
          aria-expanded={openIndexes.includes(i)}
        >
          <IconWrapper
            icon={KeyboardArrowDown}
            element="span"
            size="medium"
            className={classnames(
              accordionStyles.icon,
              openIndexes.includes(i) && accordionStyles.flipped
            )}
          />
          <Button
            className={accordionStyles.heading}
            onClick={() => showHide(i)}
            type="reset"
            isBlock
          >
            <Heading level={2} size={6} weight="regular" margin="0">
              {ReactHtmlParser(panel.q)}
            </Heading>
          </Button>
          <div
            className={classnames(
              accordionStyles.text,
              openIndexes.includes(i) && accordionStyles.open
            )}
          >
            <Spacer padding="xSmall medium medium">
              <Divider margin="xSmall 0 small" alignLeft />
              <Text size="medium" margin="0">
                {ReactHtmlParser(panel.a)}
              </Text>
            </Spacer>
          </div>
        </div>
      ))}
    </>
  )
}

Accordion.propTypes = {
  data: PropTypes.array.isRequired,
}

export default Accordion
